import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./router"
import weChatAuth from "../wechat/weChatAuth";
import weChatShare from "../wechat/weChatShare";
Vue.use(VueRouter);


const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
})

//路由验证
router.beforeEach((to,from,next) => {
    //设置标题
    document.title = to.meta.title ? to.meta.title : 'UitaUita'
    //如果需要授权
    if(to.meta.isAuth){
        // console.log(location.pathname )
        let token = localStorage.getItem("w_token")
        if(!token){
            weChatAuth().then(res => {
                //设置分享
                if(to.meta.isShare){
                    let nickname = window.localStorage.getItem('wx_name')
                    if(to.meta.shareData){
                        to.meta.shareData.title = to.meta?.shareData?.title?.replace('xxx',nickname)
                    }
                    weChatShare(to.meta.shareData)
                }else{
                    weChatShare()
                }
                next()
            })
        }else{
            //设置分享
            if(to.meta.isShare){
                let nickname = window.localStorage.getItem('wx_name')
                if(to.meta.shareData){
                    to.meta.shareData.title = to.meta?.shareData?.title?.replace('xxx',nickname)
                }
                weChatShare(to.meta.shareData)
            }else{
                weChatShare()
            }
            next()
        }
    }else{
		next()
	}

})
router.afterEach((to, from) => {
    setTimeout(() => {
        // 解决ios移动端 软键盘收起后，页面内容被顶上去，不下滑回原处
        const inputs = Array.from(document.querySelectorAll("input"));
        inputs.forEach(input => input.addEventListener("blur", window.scroll(0, 0)));
    }, 0);
    window.scrollTo(0,0)
});


export default router;
