import axios from "axios"
import weChatAuth from "../wechat/weChatAuth";

//创建实例
const instancel = axios.create({
    timeout: 13000,
    baseURL: (process.env.VUE_APP_SHARE_LINK || "http://test6.tuzuu.com") + "/api/v1",
});


//请求拦截
instancel.interceptors.request.use(request => {
    const token = localStorage.getItem("w_token");

    if (token && request.url.indexOf('http') == -1){
        request.headers.Authorization = 'Bearer ' + token;
    }

    return request;
}, err => {
    return Promise.reject(err)
})

//响应拦截
instancel.interceptors.response.use(config => {
    if(config.status === 200){
        //状态不对，清除token，刷新
        if(config.data.code == 401){
            localStorage.removeItem("w_token")
            weChatAuth().then(() => {
                console.log('状态不对')
            })
        }else{
            return config.data
        }
    }
},err => {
    if(err.response.status === 401){
        localStorage.removeItem("w_token");
        localStorage.removeItem("wx_name")
        weChatAuth().then(() => {
        //     location.reload();
        })
    }
})

const request = {}
//poost请求
request.post = (url,params,options) => {
    return new Promise((resolve,reject) => {
        instancel.post(url,params,options).then(res => {
            resolve(res)
        },err => {
            reject(err)
        })
    })
}
request.get = (url,params) =>{
    return new Promise((resolve,reject) => {
        instancel.get(url,{params:params}).then(res=>{
			resolve(res)
		},err=>{
			reject(err)
		})
    })
}

export default request