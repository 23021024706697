<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    export default {
        created() {

		},
        watch: {
            $route() {
                if (window._czc) {
                    let location = window.location;
                    let contentUrl = location.pathname + location.hash;
                    let refererUrl = "/";
                    window._czc.push(["_trackPageview", contentUrl, refererUrl]);
                }
            }
        },
        mounted() {
            // 友盟统计添加
            const script = document.createElement("script");
            script.src = "https://s4.cnzz.com/z_stat.php?id=1280108047&web_id=1280108047";
            script.language = "JavaScript";
            document.body.appendChild(script);
        },
    }
</script>

<style>
    * {
        padding: 0px;
        margin: 0px
    }

    body {
        font-family: Helvetica, Microsoft Yahei;
        font-size: 16px;
    }
</style>
