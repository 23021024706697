import request from "@/http/request"

//设置appid
let appid;
if (process.env.VUE_APP_RUN_ENV === 'prod') {
    appid = "wxed263d14aea86c29";
} else {
    appid = "wxec50f5dd6354a937";
}

export default function weChatAuth() {
    return new Promise((resolve, reject) => {
        //获取query参数，存储
        const originQuery = location.search.slice(1).split("&")
        let query = {}
        originQuery.forEach((item) => {
            let arr = item.split("=")
            query[arr[0]] = arr[1]
        })
        const code = query.code
        const wx_code = sessionStorage.getItem("wx_code")
        if (code) {
            //如果本地缓存和路径不一致，重新存储
            if (wx_code !== code) {
                sessionStorage.setItem("wx_code", code)
                request.get("wechat_login_by_code", {
                    wx_code: code
                }).then(res => {
                    console.log(res)
                    if (res.code == 1) {
                        localStorage.setItem("w_token", res.data.token)
                        localStorage.setItem("wx_name",res.data.nickname)
                    }
                    resolve()
                })
            } else {
                toAuth()
            }
        } else {
            toAuth()
        }
    })
}

function toAuth() {
    //设置随机的state
    let state = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);

    //获取query
    const originQuery = location.search.slice(1).split("&")
    let query = {}
    originQuery.forEach((item) => {
        let arr = item.split("=")
        query[arr[0]] = arr[1]
    })

    if (query.code) {
        delete query.code
        delete query.state
    }
    let str = ''
    for (const key in query) {
        if (query[key]) {
            str += `${key}=${query[key]}&`
        }
    }
    let url = (process.env.VUE_APP_SHARE_LINK || 'http://test6.tuzuu.com') + location.pathname + "?" + str;
    url = url.slice(0, url.length - 1);
    location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=${state}&connect_redirect=1#wechat_redirect`;
}