import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import request from "./http/request"
import "./assets/index.css"
import "./assets/font.css"
import "./assets/icon.js"
import "./vant"
import 'amfe-flexible'
import {optionalChaining} from "./until/index"

Vue.config.productionTip = false;
Vue.prototype.$request = request

Vue.prototype.$$ = optionalChaining

window._czc = []


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
