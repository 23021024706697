export default [
    {
        path: "/",
        redirect: "/home",
    },
    {
        //首页
        path: '/home',
        component: () => import("@/views/Home/index"),
        meta: {
            title: 'UitaUita｜每日营养专家',
            isAuth: true,
            isShare:true,
            shareData:{
                title:"0元7天免费营养健康营",
                desc:"7天营养师1对1，已有57292人参加，健康快人一步，还剩27个名额，点击和我一起改善健康吧～",
                link:'/home'
            }
        }
    },
    {
        //质量认证
        path: "/quality",
        component:() => import("@/views/Home/Quality"),
        meta: {
            title: '',
            isAuth: true,
			isShare:true,
        }
    },
    {
      //专业团队
        path: "/team",
        component:() => import("@/views/Home/Team"),
        meta: {
            title: '',
            isAuth: true,
			isShare:true,
        }
    },
    {
        //严谨科学
        path: "/science",
        component:() => import("@/views/Home/Science"),
        meta: {
            title: '',
            isAuth: true,
			isShare:true,
        }
    },
    {
        //推广页
        path: "/extension",
        component:() => import("@/views/Home/Extension"),
        meta: {
            title: '邀请好友得现金',
            isAuth: true,
            isShare:true,
            shareData:{
                title:"成为UitaUita推广大使，获取推广奖励！",
                desc:"上次有人错过了，感觉少了一个亿",
                link:'/extension'
            }
        }
    },
    {
        //问卷调查
        path: "/survey",
        component: () => import("@/views/Mass/Survey"),
        meta: {
            title: 'UitaUita问卷调查',
            isAuth: true,
			isShare:true,
        }
    },
    {
        //产品选择页
        path: "/select",
        component: () => import("@/views/Product/Select"),
        meta: {
            title: '360度了解营养品',
            isAuth: true,
            isShare:true,
            shareData:{
                title:"身体营养素知多少？",
                desc:"了解身体了解营养，从本质上解决健康问题，7天0元营养健康营，和我一起参加吧>>>",
                link:'/home'
            }
        }
    },
    {
        // 查询结果
        path: "/mass",
        component: () => import("@/views/Mass/Mass"),
        meta: {
            title: '',
            isAuth: true,
			isShare:true,
        }
    },
    {
        // 营养方案
        path: "/scheme/:id/:sn",
        component: () => import("@/views/Mass/Scheme"),
        meta: {
            title: '',
            isAuth: true,
			isShare:true,
        }
    },
    {
        // 检测结果
        path: "/mytest/:id",
        component: () => import("@/views/Mass/Mytest"),
        meta: {
            title: '',
            isAuth: true,
			isShare:true,
        }
    },
    {
        // 404页面
        path: "*",
        component: () => import("../views/404"),
        meta: {
            title: "404",
        },
    },
    {
        //产品页-详情
        path: "/product/detail/:id",
        component: () => import("@/views/Product/Detail"),
        meta: {
            title: '',
            isAuth: true,
			isShare:true,
        }
    },
    {
        //我的列表
        path: "/my",
        component:() => import("@/views/My/My"),
        meta: {
            title: 'UitaUita｜专属健康评估问卷',
            isAuth: true,
            isShare:true,
            shareData:{
                title:"权威膳食FFQs问卷，限时免费",
                desc:"点击即刻一键获取你的专属健康报告，进入直接开始>>>",
                link:'/home'
            }
        }
    },
    {
        //专属顾问
        path:"/my/consult",
        component:() => import("@/views/My/Consul"),
        meta:{
            title:"添加专属营养顾问",
            isAuth: true,
            isShare:true,
            shareData:{
                title:"0元7天免费营养健康营",
                desc:"7天营养师1对1，已有57292人参加，健康快人一步，还剩27个名额，点击和我一起改善健康吧～",
                link:'/home'
            }
        }
    },
    {
        //专属顾问2
        path:"/my/end_consult",
        component:() => import("@/views/My/EndConsul"),
        meta:{
            title:"添加专属营养顾问",
            isAuth: true,
            isShare:true,
            shareData:{
                title:"0元7天免费营养健康营",
                desc:"7天营养师1对1，已有57292人参加，健康快人一步，还剩27个名额，点击和我一起改善健康吧～",
                link:'/home'
            }
        }
    },
    {
        //健康顾问
        path:"/my/healthy",
        component:() => import("@/views/My/Healthy"),
        meta:{
            title:"我的健康顾问",
            isAuth: true,
			isShare:true,
        }
    },
    {
        //领取优惠券
        path:"/my/get_coupons",
        component:() => import("@/views/My/GetCoupons"),
        meta: {
            title: "优惠券",
            isAuth: true,
			isShare:true,
        }
    },
    {
        //使用优惠券
        path:"/my/use_coupons",
        component:() => import("@/views/My/UseCoupons"),
        meta: {
            title: "我的优惠券",
            isAuth: true,
			isShare:true
        }
    },
    {
        //邀请
        path:"/my/invita",
        component:() => import("@/views/My/Invita"),
        meta: {
            title: "UitaUita｜邀好友返现金",
            isAuth: true,
            isShare:true,
            shareData:{
                title:"【xxx】送你7天营养健康营",
                desc:"7天营养师1对1，已有57292人参加，健康快人一步，还剩27个名额，点击和我一起改善健康吧～",
                link:'/home'
            }
        }
    },
    {
        //提现
        path:"/my/cashout",
        component:() => import("@/views/My/CashOut"),
        meta: {
            title: "",
            isAuth: true,
            isShare:true,
        }
    },
    {
        //扫一扫
        path:"/my/express",
        component:() => import("@/views/My/Express"),
        meta: {
            title: "一键呼叫快递小哥",
            isAuth: true,
            isShare:true,
        }
    },
    {
        //扫一扫
        path:"/my/expr_success",
        component:() => import("@/views/My/ExpressSuccess"),
        meta: {
            title: "一键呼叫快递小哥",
            isAuth: true,
            isShare:true,
        }
    },
    {
        //商品详情
        path:"/product/order/:id",
        component:() => import("@/views/Product/Order"),
        meta: {
            title: "UitaUita｜360天定制营养包",
            isAuth: true,
            isShare:true,
            shareData:{
                title:"0元7天免费营养健康营",
                desc:"7天营养师1对1，已有57292人参加，健康快人一步，还剩27个名额，点击和我一起改善健康吧～",
                link:'/home'
            }
        }
    },
    {
        //支付成功
        path:"/product/success/:sn",
        component:() => import("@/views/Product/Success"),
        meta: {
            title: "",
            isAuth: true,
			isShare:true
        }
    },
    {
        //填写地址
        path:"/product/address/:sn",
        component:() => import("@/views/Product/Address"),
        meta: {
            title: "",
            isAuth: true,
			isShare:true
        }
    },
    {
        //订单详情
        path:"/product/show/:sn",
        component:() => import("@/views/Product/OrderDetail"),
        meta: {
            title: "",
            isAuth: true,
			isShare:true
        }
    },
    {
        //公众号
        path:"/menu",
        component:() => import("@/views/Home/Menu"),
        meta: {
            title: "品质与精准科学",
            isAuth: true,
			isShare:true,
            shareData:{
                title:"选择「UitaUita」的理由",
                desc:"来了解一下「UitaUita」吧",
                link:'/menu',
                img:'https://khome2.tuzuu.com/vita/share2.png'
            }
        }
    }

]
