import wx from "weixin-js-sdk"
import request from "../http/request";

const wxApiList = ['updateAppMessageShareData', 'updateTimelineShareData', 'chooseWXPay']

window.addEventListener('beforeunload', () => {
    window.sessionStorage.setItem('isload', 'true')
})
window.addEventListener("pagehide", () => {
    window.sessionStorage.setItem('isload', 'true') //监听刷新
})

async function wxShare(shareConfig = {}) {
    let isWeXin = navigator.userAgent.toLowerCase().indexOf("micromessenger") != -1
    if (isWeXin) {
        let nowDate = new Date()
        //判断是否是独立分享页面
        let isSeparate = false
        if(shareConfig.title){
            isSeparate = true
        }
        let result = {}
        //获取本地存储过期时间
        let oldDate = window.sessionStorage.getItem("wxOverdueTime")
        if (nowDate.getTime() > oldDate || window.sessionStorage.getItem('isload') == 'true' || isSeparate) {
            console.log('发送请求')
            console.log('---------------------------是不是独立页面' + isSeparate)
            //如果过期或者缓存不存在，重新请求配置
            result = await request.get("/get_jssdk", {
                page_url: window.location.href.split('#')[0]
            })
            if(!isSeparate){
                window.sessionStorage.setItem('wx_config',JSON.stringify(result.data))
            }

            //设置过期时间
            let date = new Date()
            let nowMin = date.getMinutes()
            date.setMinutes(nowMin+5)
            window.sessionStorage.setItem('wxOverdueTime',date.getTime())
            window.sessionStorage.setItem('isload','false')

        } else {
            console.log('没过期')
        }
        let data = JSON.parse(window.sessionStorage.getItem('wx_config')) || result.data

        const shareDataObj = {
            title: shareConfig.title ? shareConfig.title : process.env.VUE_APP_SHARE_TITLE,
            desc: shareConfig.desc ? shareConfig.desc : process.env.VUE_APP_SHARE_DESC,
            link: shareConfig.link ? process.env.VUE_APP_SHARE_LINK + shareConfig.link : process.env.VUE_APP_SHARE_LINK + "/home",
            imgUrl: shareConfig.img ? shareConfig.img : process.env.VUE_APP_SHARE_IMG,
        }
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: data.jssdk.appId, // 必填，公众号的唯一标识
            timestamp: data.jssdk.timestamp, // 必填，生成签名的时间戳
            nonceStr: data.jssdk.nonceStr, // 必填，生成签名的随机串
            signature: data.jssdk.signature,// 必填，签名
            jsApiList: data.jssdk.jsApiList, // 必填，需要使用的JS接口列表
            openTagList: ['wx-open-launch-weapp']
        })
        wx.ready(function () {
            if (wx.updateAppMessageShareData) {
                wx.updateAppMessageShareData({
                    title: shareDataObj.title,
                    desc: shareDataObj.desc,
                    link: shareDataObj.link,
                    imgUrl: shareDataObj.imgUrl,
                    success: function () {
                        console.log('分享朋友成功')
                    },
                    fail: function (error) {
                        console.log('分享朋友失败')
                    }
                })
            }
            if (wx.updateTimelineShareData) {
                wx.updateTimelineShareData({
                    title: shareDataObj.title,
                    link: shareDataObj.link,
                    imgUrl: shareDataObj.imgUrl,
                    success: function () {
                        console.log('分享朋友圈')
                    },
                    fail: function () {
                        console.log('分享朋友圈失败')
                    }
                })
            }
            if (wx.onMenuShareAppMessage) {
                wx.onMenuShareAppMessage({
                    title: shareDataObj.title,
                    desc: shareDataObj.desc,
                    link: shareDataObj.link,
                    imgUrl: shareDataObj.imgUrl,
                    success: function () {
                        console.log('旧版本分享朋友成功')
                    },
                    fail: function () {
                        // console.log('rnmsds=')
                        console.log('旧版本分享朋友失败')
                    }
                })
            }
            if (wx.onMenuShareTimeline) {
                wx.onMenuShareTimeline({
                    title: shareDataObj.title,
                    link: shareDataObj.link,
                    imgUrl: shareDataObj.imgUrl,
                    success: function () {
                        console.log('旧版本分享朋友圈')
                    },
                    fail: function () {
                        console.log('旧版本分享朋友圈失败')
                    }
                })
            }
        })

    } else {
        console.log('当前环境不是微信浏览器')
        return false
    }

}

export default wxShare