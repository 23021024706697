import Vue from "vue";
import 'vant/lib/index.css';

import {
    Icon,
    Image as VanImage,
    NumberKeyboard,
    Toast,
    Dialog,
    Button,
    CountDown,
    Swipe,
    SwipeItem,
    Checkbox,
    Popup,
    Empty,
    Form,
    Field,
    Area,
    Cell,
    CellGroup,
    TreeSelect
} from "vant"

Vue.use(Icon )
    .use(VanImage)
    .use(NumberKeyboard )
    .use(Toast)
    .use(Dialog)
    .use(Button)
    .use(CountDown)
    .use(Swipe)
    .use(SwipeItem)
    .use(Checkbox)
    .use(Popup )
    .use(Empty )
    .use(Form )
    .use(Field )
    .use(Area )
    .use(CellGroup )
    .use(Cell)
    .use(TreeSelect)
